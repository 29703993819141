import React, { useEffect } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import LogoIcon from '../../svg/LogoIcon';
import { Link } from 'gatsby';
import { useIntl } from 'gatsby-plugin-intl';
import { Helmet } from 'react-helmet';
import CookieConsent, { Cookies } from 'react-cookie-consent';
import favicon from '../../../static/images/favicon.png';
// import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies';

const Header = () => {
  const toggleMenu = () => {
    const menuBtn = document.querySelector('.contLigne');
    const newMenu = document.querySelector('.menu2');
    menuBtn.classList.toggle('active');
    newMenu.classList.toggle('menuColumn');
  };

  useEffect(() => {
    const menuBtn = document.querySelector('.contLigne');
    menuBtn.addEventListener('click', toggleMenu);
    const topLink = document.querySelector('.topLink');
    topLink.addEventListener('click', toggleMenu);
    const servicesLink = document.querySelector('.servicesLink');
    servicesLink.addEventListener('click', toggleMenu);
    const actionsLink = document.querySelector('.actionsLink');
    actionsLink.addEventListener('click', toggleMenu);
    const realisationsLink = document.querySelector('.realisationsLink');
    realisationsLink.addEventListener('click', toggleMenu);
    const contactLink = document.querySelector('.contactLink');
    contactLink.addEventListener('click', toggleMenu);

    return () => {
      menuBtn.removeEventListener('click', toggleMenu);
      topLink.removeEventListener('click', toggleMenu);
      servicesLink.removeEventListener('click', toggleMenu);
      actionsLink.removeEventListener('click', toggleMenu);
      realisationsLink.removeEventListener('click', toggleMenu);
      contactLink.removeEventListener('click', toggleMenu);
    };
  }, []);

  const intl = useIntl();
  const accueil = intl.formatMessage({ id: 'accueil' });
  const services = intl.formatMessage({ id: 'services' });
  const actions = intl.formatMessage({ id: 'actions' });
  const realisations = intl.formatMessage({ id: 'realisations' });
  const contact = intl.formatMessage({ id: 'contact' });
  const messageBanner = intl.formatMessage({ id: 'banner.message' });
  const messageBannerPolicy = intl.formatMessage({ id: 'banner.policy' });
  const messageBannerMore = intl.formatMessage({ id: 'banner.endmessage' });
  const declineButton = intl.formatMessage({ id: 'banner.decline' });
  const acceptButton = intl.formatMessage({ id: 'banner.accept' });
  const faviconLinks = [
    { rel: 'icon', type: 'image/png', sizes: '48x46', href: favicon }
  ];

  return (
    <header className='sticky top-0 bg-white shadow'>
      <Helmet link={faviconLinks}>
        <title>Artngreen | Jean-Emmanuel Dupont</title>
        <meta
          name='description'
          content='Jardinier Paysagiste à Fayence, Entretien et création de jardins'
        />
        <meta name='viewport' content='width=device-width, initial-scale=1' />
        <meta name='google-site-verification' content='H6KMeBBgIEKYKuxU9781A_qp0OKJ7aeJKTXdHqs1fbw' />
        <meta
          name='keywords'
          content="artngreen, art\'n\'green, Art\'n\'green, artngreen, Artngreen, Artngreen-paysages, art\'n\'green-paysages, Jardinier fayence, paysagiste fayence, élagage fayence,
          arrosage automatique fayence, création jardin fayence, ArtnGreen Jean-Emmanuel DUPONT,
          paysagiste seillans, Jardinier seillans, élagage seillans, garden creation fayence, garden creation seillans"
        />
        <meta name='robots' content='index, follow' />
      </Helmet>
      <div className='flexheader container flex sm:flex-row justify-between items-center mx-auto py-2 newpx-8'>
        <div className=' flex items-center text-2xl '>
          <div className='w-16 mr-3 '>
            <LogoIcon />
          </div>
          <span className='logoname'>Art'n'Green</span>
        </div>
        <div className='menu flex mt-4 sm:mt-0'>
          <AnchorLink className='px-4' href='#top'>
            {accueil}
          </AnchorLink>
          <AnchorLink className='px-4' href='#services'>
            {services}
          </AnchorLink>
          <AnchorLink className='px-4' href='#actions'>
            {actions}
          </AnchorLink>
          <AnchorLink className='px-4' href='#realisations'>
            {realisations}
          </AnchorLink>
          <AnchorLink className='px-4' href='#contact'>
            {contact}
          </AnchorLink>
        </div>

        <div className='flag-container'>
          <Link className='flagFr' to='/fr' />
          <Link className='flagEn' to='/en' />
        </div>
        <div className='box'>
          <div className='flag-container-responsive'>
            <Link className='flagFr' to='/fr' />
            <Link className='flagEn' to='/en' />
          </div>
          <div className='contLigne btn9'>
            <div className='lignes' />
            <div className='lignes' />
            <div className='lignes' />
          </div>
        </div>
      </div>
      <div className='menu2 flex mt-4 sm:mt-0 dropdown_menu'>
        <AnchorLink className='px-4 topLink menu_item_1' href='#top'>
          {accueil}
        </AnchorLink>
        <AnchorLink className='px-4 servicesLink menu_item_2' href='#services'>
          {services}
        </AnchorLink>
        <AnchorLink className='px-4 actionsLink menu_item_3' href='#actions'>
          {actions}
        </AnchorLink>
        <AnchorLink className='px-4 realisationsLink menu_item_4' href='#realisations'>
          {realisations}
        </AnchorLink>
        <AnchorLink className='px-4 contactLink menu_item_5' href='#contact'>
          {contact}
        </AnchorLink>
      </div>
      <CookieConsent
        // debug
        location='bottom'
        style={{ background: '#705d3b' }}
        buttonStyle={{ background: '#fff', fontSize: '13px' }}
        buttonText={acceptButton}
        declineButtonStyle={{ background: 'rgb(59, 50, 40)', fontSize: '14px' }}
        declineButtonText={declineButton}
        expires={365}
        sameSite='lax'
        onAccept={() => {
          // initializeAndTrack(useLocation);
          Cookies.set('gatsby-gdpr-google-analytics', 'true', { expires: 365 });
        }}
        enableDeclineButton
        setDeclineCookie
        cookieName='gatsby-gdpr-google-analytics'
      >
        {messageBanner} <Link to='/privacy'>{messageBannerPolicy}</Link>
        {messageBannerMore}.
      </CookieConsent>
    </header>
  );
};

export default Header;
