import React from 'react';
import Header from '../components/layout/Header';
import Layout from '../components/layout/Layout';
import Card from '../components/Card';
import SplitSection from '../components/SplitSection';
import Section2img from '../svg/Section2img';
import Section3img from '../svg/Section3img';
import Section4img from '../svg/Section4img';
import Section5img from '../svg/Section5img';
import Section6img from '../svg/Section6img';
import MainBackgroundImage from '../components/MainBackgroundImage';
import ReCAPTCHA from 'react-google-recaptcha';
import { useIntl } from 'gatsby-plugin-intl';
import MyGallery from '../components/MyGallery';

const env = process.env.NODE_ENV || 'development';
const Index = () => {
  const intl = useIntl();
  const textImg2 = intl.formatMessage({ id: 'textImg2' });
  const textImg3 = intl.formatMessage({ id: 'textImg3' });
  const textImg4 = intl.formatMessage({ id: 'textImg4' });
  const textImg5 = intl.formatMessage({ id: 'textImg5' });
  const titreImg2 = intl.formatMessage({ id: 'titreImg2' });
  const titreImg3 = intl.formatMessage({ id: 'titreImg3' });
  const titreImg4 = intl.formatMessage({ id: 'titreImg4' });
  const titreImg5 = intl.formatMessage({ id: 'titreImg5' });
  const titreGallerie = intl.formatMessage({ id: 'titreGallerie' });
  const textContacter = intl.formatMessage({ id: 'textContacter' });
  const vignette1Titre = intl.formatMessage({ id: 'vignette1Titre' });
  const vignette2Titre = intl.formatMessage({ id: 'vignette2Titre' });
  const vignette3Titre = intl.formatMessage({ id: 'vignette3Titre' });
  const vignette4Titre = intl.formatMessage({ id: 'vignette4Titre' });
  const vignette5Titre = intl.formatMessage({ id: 'vignette5Titre' });
  const vignette1Texte = intl.formatMessage({ id: 'vignette1Texte' });
  const vignette2Texte = intl.formatMessage({ id: 'vignette2Texte' });
  const vignette3Texte = intl.formatMessage({ id: 'vignette3Texte' });
  const vignette4Texte = intl.formatMessage({ id: 'vignette4Texte' });
  const vignette5Texte = intl.formatMessage({ id: 'vignette5Texte' });
  const associationTitre = intl.formatMessage({ id: 'associationTitre' });
  const associationTexte = intl.formatMessage({ id: 'associationTexte' });
  const submit = intl.formatMessage({ id: 'submit' });
  const titreServices = intl.formatMessage({ id: 'titreServices' });
  const businessInfoTitle = intl.formatMessage({ id: 'travail_info.plage_horraire' });
  const businessDays = intl.formatMessage({ id: 'travail_info.jours' });
  const businessDay6 = intl.formatMessage({ id: 'travail_info.jour_6' });
  const businessDay7 = intl.formatMessage({ id: 'travail_info.jour_7' });
  const contacter = intl.formatMessage({ id: 'contacter' });

  return (
    <Layout>
      <Header />
      <section id='top' className='top'>
        <MainBackgroundImage />
      </section>
      <section id='services' className='py-20 lg:pb-40 lg:pt-48'>
        <div className='container mx-auto text-center'>
          <h2 className='text-3xl lg:text-5xl font-semibold'>{titreServices}</h2>
          <div className='flex flex-wrap flex-col sm:flex-row sm:-mx-3 mt-12'>
            <div className='flex-1 px-3'>
              <Card className='mb-8 imgHover'>
                <p className='font-semibold text-xl'>{vignette1Titre}</p>
                <p className='mt-4'>
                  {vignette1Texte}
                </p>
              </Card>
            </div>
            <div className='flex-1 px-3'>
              <Card className='mb-8 imgHover'>
                <p className='font-semibold text-xl'>{vignette2Titre}</p>
                <p className='mt-4'>
                  {vignette2Texte}
                </p>
              </Card>
            </div>
            <div className='flex-1 px-3'>
              <Card className='mb-8 imgHover'>
                <p className='font-semibold text-xl'>{vignette3Titre}</p>
                <p className='mt-4'>
                  {vignette3Texte}
                </p>
              </Card>
            </div>
            <div className='flex-1 px-3'>
              <Card className='mb-8 imgHover'>
                <p className='font-semibold text-xl'>{vignette4Titre}</p>
                <p className='mt-4'>
                  {vignette4Texte}
                </p>
              </Card>
            </div>
            <div className='flex-1 px-3'>
              <Card className='mb-8 imgHover'>
                <p className='font-semibold text-xl'>{vignette5Titre}</p>
                <p className='mt-4'>
                  {vignette5Texte}
                </p>
              </Card>
            </div>
          </div>
        </div>
      </section>
      <SplitSection
        primarySlot={
          <div className='lg:pr-32 xl:pr-48'>
            <h3 className='text-3xl font-semibold leading-tight'> {titreImg2}</h3>
            <p className='mt-8 text-xl font-light leading-relaxed'>
              {textImg2}
            </p>
          </div>
      }
        secondarySlot={<Section2img />}
      />
      <SplitSection
        reverseOrder
        primarySlot={
          <div className='lg:pl-32 xl:pl-48'>
            <h3 className='text-3xl font-semibold leading-tight'>
              {titreImg3}
            </h3>
            <p className='mt-8 text-xl font-light leading-relaxed'>
              {textImg3}
            </p>
          </div>
      }
        secondarySlot={<Section3img />}
      />
      <SplitSection
        primarySlot={
          <div className='lg:pr-32 xl:pr-48'>
            <h3 className='text-3xl font-semibold leading-tight'>
              {titreImg4}
            </h3>
            <p className='mt-8 text-xl font-light leading-relaxed'>
              {textImg4}
            </p>
          </div>
      }
        secondarySlot={<Section4img />}
      />
      <SplitSection
        reverseOrder
        primarySlot={
          <div className='lg:pl-32 xl:pl-48'>
            <h3 className='text-3xl font-semibold leading-tight'>
              {titreImg5}
            </h3>
            <p className='mt-8 text-xl font-light leading-relaxed'>
              {textImg5}
            </p>
          </div>
      }
        secondarySlot={<Section5img />}
      />
      <SplitSection
        id='actions'
        reverseOrder
        primarySlot={
          <div className=' section6Container'>
            <h3 className='text-3xl font-semibold leading-tight'>
              {associationTitre}
            </h3>
            <p className='mt-8 text-xl font-light leading-relaxed'>
              {associationTexte}
            </p>
          </div>
      }
        secondarySlot={<Section6img />}
      />
      <section id='realisations' className='py-20 lg:py-40'>
        <h2 className='galleryTitle text-3xl lg:text-5xl font-semibold'>
          {titreGallerie}
        </h2>
        <div className='container galleryImages '>

          <MyGallery />
        </div>
      </section>
      <section id='contact' className='container mx-auto my-20 py-24 bg-white rounded-lg text-center'>
        <div className='mb-8'>
          <p className='mb-4'><b>{businessInfoTitle}</b></p>
          <p><b>{businessDays}</b></p>
          <p><b>{businessDay6}</b></p>
          <p><b>{businessDay7}</b></p>
        </div>
        <h3 className='text-5xl font-semibold'>{contacter}</h3>

        <p className='mt-8 text-xl font-light pb-10'>
          {textContacter}
        </p>

        <form
          className='w-2/3 sm: w-4/5 mx-auto forms'
          name='contact'
          method='POST'
          data-netlify='true'
          data-netlify-honeypot='bot-field'
          data-netlify-recaptcha='true'
        >
          <input type='hidden' name='bot-field' />
          <input type='hidden' name='form-name' value='contact' />
          <div className='mb-3 pt-0'>
            <input
              type='text'
              name='name'
              id='name'
              placeholder='Name'
              required
              className='px-2 py-1 placeholder-gray-400 text-gray-600 relative bg-white bg-white rounded text-sm border border-gray-400 outline-none focus:outline-none focus:ring w-full'
            />
          </div>

          <div className='mb-3 pt-0'>
            <input
              type='email'
              name='email'
              id='email'
              placeholder='Email'
              required
              className='px-3 py-3 placeholder-gray-400 text-gray-600 relative bg-white bg-white rounded text-sm border border-gray-400 outline-none focus:outline-none focus:ring w-full'
            />
          </div>
          <div className='mb-3 pt-0'>
            <input
              type='tel'
              name='tel'
              id='tel'
              placeholder='phone : e.g : 0000000000'
              pattern='[0-9]{3}[0-9]{3}[0-9]{4}'
              required
              className='px-3 py-3 placeholder-gray-400 text-gray-600 relative bg-white bg-white rounded text-sm border border-gray-400 outline-none focus:outline-none focus:ring w-full'
            />
          </div>
          <div className='pt-0'>
            <textarea
              type='text'
              name='message'
              id='message'
              placeholder='Message'
              required
              className='px-3 py-4 placeholder-gray-400 text-gray-600 relative bg-white bg-white rounded text-base border border-gray-400 outline-none focus:outline-none focus:ring w-full'
            />
          </div>
          <div className=' divCaptcha surroundCaptcha'><ReCAPTCHA sitekey={process.env.SITE_RECAPTCHA_KEY} /></div>
          <ul className='actions' style={{ marginTop: 30 }}>
            <li>
              <input className='contactButton py-3 px-8 text-sm rounded text-white' type='submit' name='submit' value={submit} />
            </li>
          </ul>
        </form>

      </section>
    </Layout>
  );
};

export default Index;
