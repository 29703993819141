import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox';

const options = {
  buttons: {
    showDownloadButton: false
  },
  thumbnails: {
    thumbnailsSize: ['100px', '80px']
  },
  caption: {
    showCaption: false
  }
};

const MyGallery = () => {
  const data = useStaticQuery(graphql`
  query {
    allFile(
      filter: {sourceInstanceName: {eq: "gallery"}
      name : { nin: ["piscine_et_jardin"] }
    }
      sort: {fields: base, order: ASC}
    ) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData(
              width: 600
              height: 500
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
              transformOptions: {fit: COVER}
              webpOptions: {quality: 50}
            )
          }
          publicURL
        }
      }
    }
  }
`);
  return (

    <div className='galleryImages container'>
      <SimpleReactLightbox>
        <SRLWrapper options={options}>
          <div className='grid grid-cols-2 md:grid-cols-3 grid-rows-12 md:grid-rows-8 gap-2'>
            {data.allFile.edges.map((item, index) => {
              return (
                <div className='image-item' key={index}>
                  <GatsbyImage image={item.node.childImageSharp.gatsbyImageData} alt={item.node.childImageSharp.gatsbyImageData.images.fallback.src.split('/')[4]} />
                </div>
              );
            })}
          </div>
        </SRLWrapper>
      </SimpleReactLightbox>
    </div>
  );
};

export default MyGallery;
