import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { BgImage } from 'gbimage-bridge';
import Fade from 'react-reveal/Fade';
import Flip from 'react-reveal/Flip';
import { useIntl } from 'gatsby-plugin-intl';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Button from '../components/Button';

const MainBackgroundImage = () => {
  const intl = useIntl();
  const textImg1 = intl.formatMessage({ id: 'textImg1' });
  const contacter = intl.formatMessage({ id: 'contacter' });
  const mainBackground = useStaticQuery(graphql`

      query {
        file(relativePath: {eq: "piscine_et_jardin.JPG"}) {
          childImageSharp {
            gatsbyImageData(
              height: 1440
              width: 1920
              quality: 50
              webpOptions: {quality: 70}
              formats: [AUTO, WEBP, AVIF]
              placeholder: BLURRED
            )
          }
        }
      }
    `);
  const image = getImage(mainBackground.file.childImageSharp.gatsbyImageData);

  return (
    <BgImage
      className='mastHead'
      image={image}
      alt='image principale montrant une propriété luxueuse avec piscine et jardin'
    >
      <Fade top cascade>
        <h1 className='text-4xl lg:text-5xl xl:text-6xl leading-none'>
          ART'n'GREEN
        </h1>
        <p className='titleName'>Jean-Emmanuel DUPONT</p>
      </Fade>
      <Flip top>
        <p className='text-xl lg:text-2xl mt-6 font-light'>
          {textImg1}
        </p>
      </Flip>
      <AnchorLink href='#contact'>
        <p className=' button-banner mt-8 md:mt-12'>
          <Button size='lg'>{contacter}</Button>
        </p>
      </AnchorLink>
    </BgImage>
  );
};
export default MainBackgroundImage;
